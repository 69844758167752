exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gallery-index-js": () => import("./../../../src/pages/gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/Home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-home-index-oldversion-js": () => import("./../../../src/pages/Home/index_oldversion.js" /* webpackChunkName: "component---src-pages-home-index-oldversion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-index-js": () => import("./../../../src/pages/LandingPage/index.js" /* webpackChunkName: "component---src-pages-landing-page-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/PrivacyPolicy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-terms-of-use-index-js": () => import("./../../../src/pages/TermsOfUse/index.js" /* webpackChunkName: "component---src-pages-terms-of-use-index-js" */)
}

